import { MouseEventHandler } from 'react';
import { Icons } from 'src/types/icons';

const icons: Record<Icons, { src: string }> = {
  logoBlack: { src: "/assets/images/logoBlack.svg" },
  logoHeader: { src: "/assets/icons/logo.svg" },
  snowMountain: { src: "/assets/images/snowMountain.svg" },
  landingPageTopRight1: { src: "/assets/images/landingPageTopRight1.svg" },
  landingPageTopRight2: { src: "/assets/images/landingPageTopRight2.svg" },
  curveRoundedLine: { src: "/assets/images/curveRoundedLine.svg" },
  curveRoundedLineMd: { src: "/assets/images/curveRoundedLineMd.svg" },
  cottageAndGreenMountains: { src: "/assets/images/cottageAndGreenMountains.svg" },
  oDot: { src: "/assets/images/oDot.svg" },
  horizontalDoubleHeadLongArrow: { src: "/assets/images/horizontalDoubleHeadLongArrow.svg" },
  dummyUserPic: { src: "/assets/images/dummyUserPic.svg" },
  dummyUserPicPng: { src: "/assets/images/dummyUserPic.png" },
  dummyUserPicSmallPng: { src: "/assets/images/dummyUserPicSmallPng.png" },
  star: { src: "/assets/images/star.svg" },
  dummyAvatar: { src: "/assets/images/dummyAvatar.svg" },
  oDotSmallOrange: { src: "/assets/images/oDotSmallOrange.svg" },
  oDotWhiteSmall: { src: "/assets/images/oDotWhiteSmall.svg" },
  headPhone: { src: "/assets/images/headPhone.svg" },
  oDotWithMountainsBackground: { src: "/assets/images/oDotWithMountainsBackground.svg" },
  cross: { src: "/assets/icons/cross.svg" },
  plus: { src: "/assets/icons/plus.svg" },
  instagram: { src: "/assets/icons/instagram.svg" },
  twitter: { src: "/assets/icons/twitter.svg" },
  google: { src: "/assets/icons/google.svg" },
  game: { src: "/assets/icons/game.svg" },
  starCream: { src: "/assets/icons/starCream.svg" },
  bestValue: { src: "/assets/images/bestValue.svg" },
  bookNowBlack: { src: "/assets/icons/bookNowBlack.svg" },
  userBlack: { src: "/assets/icons/userBlack.svg" },
  userCream: { src: "/assets/icons/userCream.svg" },
  airplaneBlack: { src: "/assets/icons/airplaneBlack.svg" },
  creditCardBlack: { src: "/assets/icons/creditCardBlack.svg" },
  helpBlack: { src: "/assets/icons/helpBlack.svg" },
  signOutBlack: { src: "/assets/icons/signOutBlack.svg" },
  creditCardCream: { src: "/assets/icons/creditCardCream.svg" },
  helpCream: { src: "/assets/icons/helpCream.svg" },
  uploadFile: { src: "/assets/icons/uploadFile.svg" },
  plusOrange: { src: "/assets/icons/plusOrange.svg" },
  minusOrange: { src: "/assets/icons/minusOrange.svg" },
  calendar: { src: "/assets/icons/calendar.svg" },
  dotLocationMarkerGreen: { src: "/assets/icons/dotLocationMarkerGreen.svg" },
  locationMarker: { src: "/assets/icons/locationMarker.svg" },
  youtube: { src: "/assets/icons/youtube.svg" },
  attentionCircle: { src: "/assets/icons/attentionCircle.svg" },
  leftLongArrowGreen: { src: "/assets/icons/leftLongArrowGreen.svg" },
  rightLongArrowGreen: { src: "/assets/icons/rightLongArrowGreen.svg" },
  downArrowHeadCream: { src: "/assets/icons/downArrowHeadCream.svg" },
  user: { src: "/assets/icons/user.svg" },
  longArrowLeftToRightBlack: { src: "/assets/icons/longArrowLeftToRightBlack.svg" },
  curveRightLine: { src: "/assets/images/curveRightLine.svg" },
  curveLeftLine: { src: "/assets/images/curveLeftLine.svg" },
  payLess: { src: "/assets/images/payLess.svg" },
  travelInStyle: { src: "/assets/images/travelInStyle.svg" },
  swimingBackground: { src: "/assets/images/swimingBackground.svg" },
  curveRoundedLineWhite: { src: "/assets/images/curveRoundedLineWhite.svg" },
  step1: { src: "/assets/images/step1.svg" },
  step1Jpg: { src: "/assets/images/step1Jpg.jpg" },
  step1Png: { src: "/assets/images/step1Png.png" },
  step2: { src: "/assets/images/step2.svg" },
  step2Jpg: { src: "/assets/images/step2Jpg.jpg" },
  step2Png: { src: "/assets/images/step2Png.png" },
  step3: { src: "/assets/images/step3.svg" },
  step3Jpg: { src: "/assets/images/step3Jpg.jpg" },
  step3Png: { src: "/assets/images/step3Png.png" },
  step3TabletPng: { src: "/assets/images/step3TabletPng.png" },
  bitCoin: { src: "/assets/images/bitCoin.svg" },
  ethereum: { src: "/assets/images/ethereum.svg" },
  apeCoin: { src: "/assets/images/apeCoin.svg" },
  liteCoin: { src: "/assets/images/liteCoin.svg" },
  usdCoin: { src: "/assets/images/usdCoin.svg" },
  polygon: { src: "/assets/images/polygon.svg" },
  visa: { src: "/assets/images/visa.svg" },
  masterCard: { src: "/assets/images/masterCard.svg" },
  americanExpress: { src: "/assets/images/americanExpress.svg" },
  discover: { src: "/assets/images/discover.svg" },
  bitCoinMobile: { src: "/assets/images/bitCoinMobile.svg" },
  ethereumMobile: { src: "/assets/images/ethereumMobile.svg" },
  apeCoinMobile: { src: "/assets/images/apeCoinMobile.svg" },
  liteCoinMobile: { src: "/assets/images/liteCoinMobile.svg" },
  usdCoinMobile: { src: "/assets/images/usdCoinMobile.svg" },
  polygonMobile: { src: "/assets/images/polygonMobile.svg" },
  visaMobile: { src: "/assets/images/visaMobile.svg" },
  masterCardMobile: { src: "/assets/images/masterCardMobile.svg" },
  americanExpressMobile: { src: "/assets/images/americanExpressMobile.svg" },
  discoverMobile: { src: "/assets/images/discoverMobile.svg" },
  bitCoinMobilePng: { src: "/assets/images/bitCoinMobilePng.png" },
  ethereumMobilePng: { src: "/assets/images/ethereumMobilePng.png" },
  usdCoinMobilePng: { src: "/assets/images/usdCoinMobilePng.png" },
  apeCoinMobilePng: { src: "/assets/images/apeCoinMobilePng.png" },
  liteCoinMobilePng: { src: "/assets/images/liteCoinMobilePng.png" },
  polygonMobilePng: { src: "/assets/images/polygonMobilePng.png" },
  visaMobilePng: { src: "/assets/images/visaMobilePng.png" },
  masterCardMobilePng: { src: "/assets/images/masterCardMobilePng.png" },
  americanExpressMobilePng: { src: "/assets/images/americanExpressMobilePng.png" },
  discoverMobilePng: { src: "/assets/images/discoverMobilePng.png" },
  hotel: { src: "/assets/images/hotel.svg" },
  ninaKovalenko: { src: "/assets/images/ninaKovalenko.svg" },
  xploriusLogoCream: { src: "/assets/images/xploriusLogoCream.svg" },
  snowMountainsBooking: { src: "/assets/images/snowMountainsBooking.svg" },
  snowMountainsBookingSmall: { src: "/assets/images/snowMountainsBookingSmall.svg" },
  curveRoundedLineCream: { src: "/assets/images/curveRoundedLineCream.svg" },
  oDotWtihBackgroundWallTree: { src: "/assets/images/oDotWtihBackgroundWallTree.svg" },
  oldHeritageAndMountain: { src: "/assets/images/oldHeritageAndMountain.svg" },
  randomLinesBgGreen: { src: "/assets/images/randomLinesBgGreen.svg" },
  oDotWIthsheepsBackground: { src: "/assets/images/oDotWIthsheepsBackground.svg" },
  helpRoundedLines: { src: "/assets/images/helpRoundedLines.svg" },
  helpRoundedLinesForTablet: { src: "/assets/images/helpRoundedLinesForTablet.svg" },
  arrowBack: { src: "/assets/images/ArrowBack.svg" },
  arrow4: { src: "/assets/images/Arrow4.svg" },
  dummyAvatar2: { src: "/assets/images/dummyAvatar2.svg" },
  dummyAvatar3: { src: "/assets/images/dummyAvatar3.svg" },
  stars: { src: "/assets/images/stars.svg" },
  landingPageTopRight1Small: { src: "/assets/images/landingPageTopRight1Small.svg" },
  landingPageTopRight1MobilePng: { src: "/assets/images/landingPageTopRight1MobilePng.png" },
  landingPageTopRight1MobileJpg: { src: "/assets/images/landingPageTopRight1MobileJpg.jpg" },
  landingPageTopRight2Small: { src: "/assets/images/landingPageTopRight2Small.svg" },
  landingPageTopRight2MobileJpg: { src: "/assets/images/landingPageTopRight2MobileJpg.jpg" },
  landingPageTopRight2MobilePng: { src: "/assets/images/landingPageTopRight2MobilePng.png" },
  curveRoundedLineSmall: { src: "/assets/images/curveRoundedLineSmall.svg" },
  lukaSmall: { src: "/assets/images/lukaSmall.svg" },
  lukaSmallJpg: { src: "/assets/images/lukaSmallJpg.jpg" },
  lukaMobilePng: { src: "/assets/images/lukaMobilePng.png" },
  lukaJpg: { src: "/assets/images/lukaJpg.jpg" },
  rohallahSmall: { src: "/assets/images/rohallahSmall.svg" },
  rohallahSmallJpg: { src: "/assets/images/rohallahSmallJpg.jpg" },
  rohAllahMobileJpg: { src: "/assets/images/rohAllahMobileJpg.jpg" },
  rohAllahMobilePng: { src: "/assets/images/rohAllahMobilePng.png" },
  rohallahJpg: { src: "/assets/images/rohallahJpg.jpg" },
  sparrow: { src: "/assets/images/sparrow.svg" },
  curveLeftLineSmall: { src: "/assets/images/curveLeftLineSmall.svg" },
  curveRightLineSmall: { src: "/assets/images/curveRightLineSmall.svg" },
  curveRoundedLineWhiteSmall: { src: "/assets/images/curveRoundedLineWhiteSmall.svg" },
  swimingBackgroundSmall: { src: "/assets/images/swimingBackgroundSmall.svg" },
  hamburger: { src: "/assets/images/hamburger.svg" },
  topLeftCornerOfLoginSignupSmall: { src: "/assets/images/topLeftCornerOfLoginSignupSmall.svg" },
  bottomRightCornerOfLoginSignupSmall: { src: "/assets/images/bottomRightCornerOfLoginSignupSmall.svg" },
  bottomRightCornerOfLoginSignupMobile: { src: "/assets/images/bottomRightCornerOfLoginSignupMobile.svg" },
  topLeftCornerOfLoginSignupMobile: { src: "/assets/images/topLeftCornerOfLoginSignupMobile.svg" },
  hotel2: { src: "/assets/images/hotel2.svg" },
  hotel3: { src: "/assets/images/hotel3.jpg" },
  hotel4: { src: "/assets/images/hotel4.jpg" },
  hotel5: { src: "/assets/images/hotel5.jpg" },
  hotel6: { src: "/assets/images/hotel6.jpg" },
  hotel7: { src: "/assets/images/hotel7.jpg" },
  hotel8: { src: "/assets/images/hotel8.jpg" },
  hotel9: { src: "/assets/images/hotel9.jpeg" },
  hotel10: { src: "/assets/images/hotel10.jpg" },
  hotel11: { src: "/assets/images/hotel11.jpg" },
  hotel12: { src: "/assets/images/hotel12.jpg" },
  hotel13: { src: "/assets/images/hotel13.jpg" },
  hotel14: { src: "/assets/images/hotel14.jpg" },
  partner1: { src: "/assets/images/partners/partner1.png" },
  partner2: { src: "/assets/images/partners/partner2.png" },
  partner3: { src: "/assets/images/partners/partner3.png" },
  partner4: { src: "/assets/images/partners/partner4.png" },
  partner5: { src: "/assets/images/partners/partner5.png" },
  partner6: { src: "/assets/images/partners/partner6.png" },
  partner7: { src: "/assets/images/partners/partner7.png" },
  partner8: { src: "/assets/images/partners/partner8.png" },
  partner9: { src: "/assets/images/partners/partner9.png" },
  partner10: { src: "/assets/images/partners/partner10.png" },
  vip1: { src: "/assets/images/vip/vip1.jpg" },
  vip2: { src: "/assets/images/vip/vip2.jpg" },
  vip3: { src: "/assets/images/vip/vip3.jpg" },
  vip4: { src: "/assets/images/vip/vip4.jpg" },
  vip5: { src: "/assets/images/vip/vip5.jpg" },
  vip6: { src: "/assets/images/vip/vip6.png" },
  vip7: { src: "/assets/images/vip/vip7.jpg" },
  vip8: { src: "/assets/images/vip/vip8.jpg" },
  aboutUsTopBackground: { src: "/assets/images/aboutUsTopBackground.svg" },
  aboutUsChineseBackground: { src: "/assets/images/aboutUsChineseBackground.svg" },
  aboutUsChineseBackgroundJpg: { src: "/assets/images/aboutUsChineseBackground.jpg" },
  nft15: { src: "/assets/images/nft15.png" },
  nft63: { src: "/assets/images/nft63.png" },
  nft137: { src: "/assets/images/nft137.png" },
  nft216: { src: "/assets/images/nft216.png" },
  nft236: { src: "/assets/images/nft236.png" },
  nft253: { src: "/assets/images/nft253.png" },
  nft362: { src: "/assets/images/nft362.png" },
  nft663: { src: "/assets/images/nft663.png" },
  nft682: { src: "/assets/images/nft682.png" },
  nft765: { src: "/assets/images/nft765.png" },
  nft810: { src: "/assets/images/nft810.png" },
  nft900: { src: "/assets/images/nft900.png" },
  nft1152: { src: "/assets/images/nft1152.png" },
  nft1196: { src: "/assets/images/nft1196.png" },
  arrowLeftSlider: { src: "/assets/images/arrowLeftSlider.svg" },
  arrowRightSlider: { src: "/assets/images/arrowRightSlider.svg" },
  founder: { src: "/assets/images/founder.svg" },
  founderPng: { src: "/assets/images/founderPng.png" },
  founderMobile: { src: "/assets/images/founderMobile.svg" },
  authBgImage: { src: "/assets/images/authBgImage.png" },
  uaeFlag: { src: "/assets/images/uaeFlag.svg" },
  eye: { src: "/assets/icons/eye.svg" },
  hidePasswordEye: { src: "/assets/icons/hidePasswordEye.svg" },
  userCopperRed: { src: "/assets/icons/userCopperRed.svg" },
  bookNowCopperRed: { src: "/assets/icons/bookNowCopperRed.svg" },
  creditCardCopperRed: { src: "/assets/icons/creditCardCopperRed.svg" },
  helpCopperRed: { src: "/assets/icons/helpCopperRed.svg" },
  landingPageTopRight2Jpg: { src: "/assets/images/landingPageTopRight2Jpg.jpg" },
  landingPageTopRight1Jpg: { src: "/assets/images/landingPageTopRight1Jpg.jpg" },
  helpRoundedLinesMobile: { src: "/assets/images/helpRoundedLinesMobile.svg" },
  muslimGirlWithHat: { src: "/assets/images/muslimGirlWithHat.svg" },
  muslimGirlWithHatPng: { src: "/assets/images/muslimGirlWithHat.png" },
  muslimGirlWithHatForTablet: { src: "/assets/images/muslimGirlWithHatForTablet.png" },
  muslimGirlWithHatForMobile: { src: "/assets/images/muslimGirlWithHatForMobile.png" },
  edit: { src: "/assets/icons/edit.svg"},
  deleteRed: { src: "/assets/icons/deleteRed.svg"},
  deleteBlack: { src: "/assets/icons/deleteBlack.svg"},
  deleteOrange: { src: "/assets/icons/deleteOrange.svg"},
  percentage: { src: "/assets/icons/percentage.svg"},
  rightShortArrowGreen: { src: "/assets/icons/rightShortArrowGreen.svg"},
  leftShortArrowGreen: { src: "/assets/icons/leftShortArrowGreen.svg"},


};

interface IProps {
  icon?: Icons;
  src?: string;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
}

export const IconSvg = ({ icon, src, className = '', disabled = false, onClick }: IProps) => {
  const iconRecord = icon ? icons[icon] : { src };
  return (
    <img
      style={{ cursor: disabled ? 'not-allowed' : '' }}
      className={className}
      src={iconRecord.src}
      alt="icon"
      onClick={onClick}
    />
  );
};
